@import url('https://rsms.me/inter/inter.css');

@font-face {
  font-family: 'Meslo';
  src: url('https://docs.pmnd.rs/fonts/meslo.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

html,
body,
#root,
.container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: white;
  color: black;
  font-family: 'Inter';
}

a {
  color: black;
}

a {
  pointer-events: all;
  color: black;
  text-decoration: none;
}

svg {
  fill: black;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 5s ease 0.5s forwards;
}
